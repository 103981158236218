// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { CodingContext } from '@imprivata-cloud/dataprivacy';
import { map } from 'rxjs/operators';
import type {
  AdminAuthParams,
  CompleteStepRequest,
  GetAADLinkParams,
  InitialGroupSyncStartRequest,
  StartSetupParams,
  DirectoriesFindData,
  AadConsentUrlGetResponse,
  CheckDpaConsentRequest,
  CheckDpaConsentResponse,
  ConsentDpaSubmitRequest,
} from '../store/initialSetup/types';
import type { Observable } from 'rxjs';
import rxClient from './rxClient';
import { directories, headers, initialSetupUrl, consentUrl } from '../constants/services';
import { adminUsernameField, StorageKeys, type TenantType } from '../constants/fieldNames';
import storage from '../utils/storage';

export const codingContext: CodingContext = CodingContext.createContextV1();

export const getConsentUrl$ = (params: GetAADLinkParams): Observable<AadConsentUrlGetResponse> => {
  return rxClient.post<AadConsentUrlGetResponse>(initialSetupUrl.AAD_LINK_URL, params).pipe(map((res) => res.data));
};

export function dpaCheck$(postBody: CheckDpaConsentRequest): Observable<CheckDpaConsentResponse> {
  return rxClient.post<CheckDpaConsentResponse>(consentUrl.DPA_CHECK, postBody).pipe(map((res) => res.data));
}

export function dpaSubmit$(postBody: ConsentDpaSubmitRequest): Observable<void> {
  return rxClient.post<void>(consentUrl.DPA_SUBMIT, postBody).pipe(map((res) => res.data));
}

export const completeSetup$ = (params: AdminAuthParams): Observable<void> => {
  const postBody: CompleteStepRequest = {
    [adminUsernameField]: params[adminUsernameField],
  };

  return rxClient.post<void>(initialSetupUrl.COMPLETE_SETUP, postBody).pipe(map((res) => res.data));
};

export function findDirectories$(): Observable<DirectoriesFindData> {
  return rxClient.post<DirectoriesFindData>(directories.FIND).pipe(map((res) => res.data));
}

export function groupSyncStart$(identityProviderId: string): Observable<void> {
  const body: InitialGroupSyncStartRequest = {
    identityProviderId,
  };
  return rxClient.post<void>(initialSetupUrl.INITIAL_GROUP_SYNC_START, body).pipe(map((res) => res.data));
}

export const startSetup$ = (params: StartSetupParams): Observable<void> => {
  storage.setItem(StorageKeys.IMPR_TENANT_TYPE, '');
  const contextHeader = codingContext.buildImprCodingCtxHeader();
  const axiosConfig = {
    headers: {
      [headers.ImprCodingCtx]: contextHeader,
    },
  };
  storage.setItem(StorageKeys.CODING_CTX, contextHeader);
  return rxClient.post<void>(initialSetupUrl.START_SETUP, params, axiosConfig).pipe(
    map((res) => {
      const tenantType = res.headers[headers.ImprTenantType] as TenantType;
      storage.setItem(StorageKeys.IMPR_TENANT_TYPE, tenantType);
      return res.data;
    }),
  );
};
